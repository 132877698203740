<template>
  <v-col cols="12" md="6" xl="4">
    <v-card class="mb-7">
      <v-toolbar
        :color="`${subEvaluation.color} lighten-3`"
        elevation="0"
        class="px-4"
      >
        <div class="text-h6 font-weight-medium">
          Evaluación #{{ index + 1 }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="index > 0"
          color="error"
          small
          @click="$emit('remove-sub-evaluation', index)"
        >
          <v-icon left> mdi-delete-circle </v-icon>
          Eliminar
        </v-btn>
      </v-toolbar>
      <v-container class="pb-4">
        <v-row class="px-4 pt-6">
          <v-col class="py-0">
            <div class="black--text mb-1 flex-column">
              <form-group
                name="nombre de la evaluación"
                :validator="$v.subEvaluation.activity_type_evaluation_type_id"
              >
                <v-select
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Nombre de la evaluación"
                  placeholder="Selecciona un tipo de evaluación"
                  :items="evaluationTypes"
                  item-text="evaluation_type"
                  item-value="id"
                  required
                  filled
                  rounded
                  dense
                  background-color="grey lighten-4"
                  color="black"
                  v-model="subEvaluation.activity_type_evaluation_type_id"
                  :disabled="!canEdit"
                ></v-select>
              </form-group>
              <span
                v-if="
                  formErrors[
                    `sub_evaluations.${index}.activity_type_evaluation_type_id`
                  ]
                "
                class="red--text text-body-1 d-block"
              >
                {{
                  formErrors[
                    `sub_evaluations.${index}.activity_type_evaluation_type_id`
                  ].join(", ")
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-4">
          <v-col class="py-0">
            <div class="black--text mb-1 flex-column">
              <form-group
                name="descripción"
                :validator="$v.subEvaluation.description"
              >
                <v-textarea
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  dense
                  background-color="grey lighten-4"
                  color="black"
                  counter
                  :label="`Descripción de la evaluación`"
                  auto-grow
                  rows="2"
                  v-model="subEvaluation.description"
                  :disabled="!canEdit"
                ></v-textarea>
              </form-group>
              <span
                v-if="formErrors[`sub_evaluations.${index}.description`]"
                class="red--text text-body-1 d-block"
              >
                {{
                  formErrors[`sub_evaluations.${index}.description`].join(", ")
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-4">
          <v-col class="py-0">
            <div class="black--text mb-1 flex-column">
              <form-group
                name="instrumento de evaluación"
                :validator="$v.subEvaluation.evaluation_instrument_document"
              >
                <v-file-input
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Instrumento de evaluación"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file-pdf-box"
                  filled
                  rounded
                  dense
                  background-color="grey lighten-4"
                  color="black"
                  accept="application/pdf"
                  truncate-length="15"
                  hint="El instrumento de evaluación no es obligatorio"
                  persistent-hint
                  v-model="subEvaluation.evaluation_instrument_document"
                  :disabled="!canEdit"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" label small>{{
                      text
                    }}</v-chip>
                  </template>
                </v-file-input>
              </form-group>
              <span
                v-if="
                  formErrors[
                    `sub_evaluations.${index}.evaluation_instrument_document`
                  ]
                "
                class="red--text text-body-1 d-block"
              >
                {{
                  formErrors[
                    `sub_evaluations.${index}.evaluation_instrument_document`
                  ].join(", ")
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-4 pb-4">
          <v-col cols="12" sm="6" class="py-0">
            <div class="black--text mb-1 flex-column">
              <form-group
                name="descripción"
                :validator="$v.subEvaluation.limit_date"
              >
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="limitDateMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha límite"
                      :validator="$v.subEvaluation.limit_date"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="subEvaluation.limit_date"
                        label="Fecha límite"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        filled
                        rounded
                        dense
                        background-color="grey lighten-4"
                        color="black"
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                        :disabled="!canEdit"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="subEvaluation.limit_date"
                    :min="evaluationDateRange.startDate"
                    :max="evaluationDateRange.endDate"
                    @input="limitDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </form-group>
              <span
                v-if="formErrors[`sub_evaluations.${index}.limit_date`]"
                class="red--text text-body-1 d-block"
              >
                {{
                  formErrors[`sub_evaluations.${index}.limit_date`].join(", ")
                }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <div class="black--text mb-1 flex-column">
              <form-group
                name="porcentaje"
                :validator="$v.subEvaluation.percent"
                :messages="errorMessages"
              >
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Porcentaje"
                  required
                  filled
                  rounded
                  dense
                  background-color="grey lighten-4"
                  color="black"
                  type="number"
                  prepend-inner-icon="mdi-percent"
                  v-model.trim="$v.subEvaluation.percent.$model"
                  :disabled="!canEdit"
                />
              </form-group>
              <span
                v-if="formErrors[`sub_evaluations.${index}.percent`]"
                class="red--text text-body-1 d-block"
              >
                {{ formErrors[`sub_evaluations.${index}.percent`].join(", ") }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import subEvaluationRepository from "@/repositories/subEvaluationRepository";
import { required, minValue, maxLength } from "vuelidate/lib/validators";

export default {
  name: "SubEvaluationEle",
  data() {
    return {
      errorMessages: {
        sumOfSubEvaluationsPercentMatchWeightLeft:
          "La sumatoria de los porcentajes de cada evaluación no debe exceder la ponderación del perfil especificada anteriormente.",
      },
      limitDateMenu: false,
    };
  },
  props: {
    subEvaluation: {
      type: Object,
    },
    index: {
      type: Number,
    },
    evaluationTypes: {
      type: Array,
    },
    weightLeft: {
      type: Number,
    },
    evaluationDateRange: {
      type: Object,
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    canEdit() {
      return this.evaluationTypes.length != 0;
    },
  },
  validations: {
    subEvaluation: {
      activity_type_evaluation_type_id: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(500),
      },
      limit_date: {
        required,
      },
      percent: {
        required,
        minValue: minValue(1),
        sumOfSubEvaluationsPercentMatchWeightLeft() {
          return this.weightLeft == 0;
        },
      },
      evaluation_instrument_document: {},
    },
  },
};
</script>
