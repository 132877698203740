<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <h3 class="card-label">
          Listado de evaluaciones
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <v-divider class="my-0"></v-divider>
    <div class="card-body">
      <v-container>
        <v-row>
          <v-col tag="p" class="text-h6 font-weight-normal">
            <strong>
              Número de evaluaciones
            </strong>
            que es posible agregar según el
            <strong>
              tipo de actividad
            </strong>
            seleccionado es:
            <strong>
              {{ numberOfSubEvaluations }}
            </strong>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <SubEvaluationEle
            v-for="(subEvaluation, index) in subEvaluations"
            :ref="`sub-evaluation-${index}`"
            :key="index"
            :index="index"
            :subEvaluation="subEvaluation"
            :evaluationTypes="evaluationTypes"
            :weightLeft="weightLeft"
            :evaluationDateRange="evaluationDateRange"
            @remove-sub-evaluation="removeSubEvaluation"
            :formErrors="formErrors"
          ></SubEvaluationEle>
          <v-col
            cols="12"
            md="6"
            xl="4"
            class="my-3"
            v-if="subEvaluations.length < numberOfSubEvaluations"
          >
            <v-card class="mb-7 fill-height" @click="addSubEvaluation">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-center mt-16 pt-16" cols="6">
                  <v-icon color="primary" x-large>
                    mdi-plus-box-multiple
                  </v-icon>
                </v-col>
                <v-col class="text-h6 text-center pt-0 mb-16 pb-16" cols="12">
                  Agregar evaluación
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import SubEvaluationEle from "@/components/elements/create_evaluation/SubEvaluationEle.vue";
import evaluationTypeRepository from "@/repositories/evaluationTypeRepository";

export default {
  name: "SubEvaluationsListCard",
  components: {
    SubEvaluationEle,
  },
  data() {
    return {
      subEvaluations: [],
      evaluationTypes: [],
    };
  },
  props: {
    activityType: {
      type: Object,
    },
    evaluationWeight: {
      type: Number,
    },
    evaluationDateRange: {
      type: Object,
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    addSubEvaluation() {
      const index = this.subEvaluations.length;
      this.subEvaluations.push({
        activity_type_evaluation_type_id: null,
        description: null,
        percent: null,
        evaluation_instrument_document: null,
        limit_date: null,
        color: this.orderedColors[index % 17],
      });
    },

    removeSubEvaluation(index) {
      this.subEvaluations.splice(index, 1);
    },

    getEvaluationTypesOfActivityType() {
      evaluationTypeRepository
        .getEvaluationTypesByActivityType(this.activityType.id)
        .then(({ data }) => {
          this.evaluationTypes = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    validateChildElements() {
      let areInvalid = true;
      this.subEvaluations.forEach((item, index) => {
        const componentRef = `sub-evaluation-${index}`;
        const [component] = this.$refs[componentRef];

        component.$v.$touch();
        areInvalid *= component.$v.$invalid;
      });
      return !!areInvalid;
    },

    resetSubEvaluationsList() {
      this.subEvaluations = [];
      this.addSubEvaluation();
      this.evaluationTypes = [];
    },
  },
  created() {
    this.addSubEvaluation();
  },
  watch: {
    activityType(newValue) {
      if (newValue && Object.keys(newValue).length) {
        this.getEvaluationTypesOfActivityType();
      }
    },
  },
  computed: {
    numberOfSubEvaluations() {
      if (Object.keys(this.activityType).length == 0) {
        return 1;
      }

      return this.activityType.number_sub_evaluations;
    },

    weightLeft() {
      const acummulatedWeight = this.subEvaluations.reduce((prev, curr) => {
        return (prev += parseFloat(curr.percent) || 0);
      }, 0);
      return this.evaluationWeight - acummulatedWeight;
    },
    orderedColors() {
      return this.colors.sort(() => Math.random() - 0.5);
    },
  },
};
</script>
